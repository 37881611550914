<template>
    <div v-if="getPolicy && getPolicy.attributes" class="container-wrapper">
        <div class="container">
            <main>
                <h2>YOUR COVER HAS BEEN UPGRADED!</h2>
                <data-table :data-to-display="dataToDisplay" />

                <h2>Anything Else?</h2>
                <div class="content-block">
                    <p><strong>You should find everything you need to continue your adventures on the My Emporium portal. If you can't, please don't hesitate to get in touch. Our friendly team will be happy to help you out!</strong></p>
                    <p><strong>Phone:</strong> 03300 244 007</p>
                    <p><strong>Email:</strong> hello@emporium.co.uk</p>
                    <p>
                        <strong>Office Opening Hours</strong>
                        Mon - Fri: 8:00am to 7:00pm<br>
                        Saturday: 8:30am to 5:00pm<br>
                        Sunday: Closed<br>
                        Bank Holiday: Closed<br>
                        Christmas & Boxing Day: Closed
                    </p>
                </div>
                <p class="asterisk-text">
                    * Any illness that starts before this date will not be covered.
                </p>
            </main>
        </div>
    </div>
    <div v-else class="container-wrapper error-container">
        <tie-alert v-if="error && error.length > 0">
            {{ error }}
        </tie-alert>
    </div>
</template>

<script>
import DataTable from '../../../../../../../components/policies/PolicyDetails/DataTable';
import TieAlert from '../../../../../../../components/tie-alert/TieAlert';
import {mapGetters} from 'vuex';

export default {
    name: 'UpgradeConfirmation',

    components: {
        DataTable,
        TieAlert
    },

    data() {
        return {
            error: 'There has been an issue retrieving your policy at this time. please try again later.',
        };
    },

    computed: {
        ...mapGetters([
            'getUpgrade'
        ]),

        getPolicy() {
            return this.$store.getters.getPolicy(this.$route.params.id);
        },

        getPaymentFrequency() {
            return this.policy.attributes['payment-term-frequency'];
        },

        dataToDisplay() {
            let upgradeOption = this.getUpgrade.data;

            if (!upgradeOption || !this.getPolicy) {
                return [];
            } else {
                let data = [
                    {
                        title: 'Policy Number',
                        value: this.$route.params.id
                    },
                    {
                        title: 'Pet Name',
                        value: this.getPolicy.attributes.insured
                    },
                    {
                        title: 'Policy Type',
                        value: upgradeOption.attributes.title
                    },
                    {
                        title: 'Policy Term',
                        value: this.getPaymentFrequency
                    },
                    {
                        title: 'New Premium',
                        value: `£${ upgradeOption.attributes.premium }`
                    },
                    {
                        title: 'Upgrade Date',
                        value: new Date().toLocaleDateString('en-GB')
                    },
                    {
                        title: ['timelimited', 'maxbenefit', 'lifetime'].indexOf(upgradeOption.attributes['policy-type-group']) > -1 ? 'Your Upgraded Cover for Illness Starts' : 'Your Cover for Illness Starts',
                        value: new Date(upgradeOption.attributes['illness-cover-start-date']).toLocaleDateString('en-GB')
                    },
                ];

                if (!this.isAnnual) {
                    data.push({
                        title: 'Next Collection',
                        value: new Date(upgradeOption.attributes['next-payment-date']).toLocaleDateString('en-GB')
                    });
                }

                return data;
            }
        },

        isAnnual() {
            return this.getPolicy.attributes['payment-frequency'] === 'A';
        }
    },

    mounted() {
        this.$store.dispatch('getPolicy', this.$route.params.id);
    }
};
</script>

<style lang="scss" scoped>
@import "../../../../../../../assets/styles/variables";
@import "../../../../../../../assets/styles/container";

.asterisk-text {
    margin-top: 1rem;
    color: #B5B5B5;
}

.error-container {
    align-items: flex-start;
}
</style>